import React from 'react'
import './RepertoireContainer.scss'
import { CarouselContainer } from '.'
import { RepertoirePage } from '../components/'
import GetQuoteButton from '../forms/GetQuoteButton'

const RepertoireContainer = ({ repertoire }) => (
  <div className="repertoireContainer">
    <h3 className="repertoireHeading">REPERTOIRE</h3>
    <CarouselContainer items={repertoire} component={RepertoirePage} />
    <GetQuoteButton color="Red" />
  </div>
)

export default RepertoireContainer
