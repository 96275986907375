import React from 'react'
import './SocialContainer.scss'
import classnames from 'classnames'
import ImageContainer from './ImageContainer'
import { socialSites } from '../constants'

const SocialContainer = ({ className }) => (
  <div className={classnames('socialContainer', className)}>
    {socialSites.map(({ icon, href }) => (
      <a key={icon} {...{ href }} target="blank" className="socialLink">
        <ImageContainer
          className="socialIcon"
          alt={icon}
          src={`/imgs/social_logos/${icon}.svg`}
        />
      </a>
    ))}
  </div>
)

export default SocialContainer
