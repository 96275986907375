import toCamelCase from '../helpers/toCamelCase'

const icons = [
  'left-arrow',
  'right-arrow',
  'play',
  'pause',
  'loading',
  'back',
  'left-arrow-red',
  'right-arrow-red',
]

const iconObject = icons.reduce((iconObject, icon) => {
  iconObject[toCamelCase(icon)] = `/imgs/navigation/${icon}.svg`
  return { ...iconObject }
}, {})

export default iconObject
