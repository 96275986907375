const repertoirePageLimit = 10

export const repertoireConverter = data => {
  let currentArray = []
  const items = data.values
  return items.reduce((masterArray, [title, artist], i) => {
    currentArray.push({ artist, title })
    if (currentArray.length === repertoirePageLimit || i === items.length - 1) {
      masterArray.push(currentArray)
      currentArray = []
    }
    return masterArray
  }, [])
}

export const testimonialConverter = data =>
  data.values.map(([testimonial, client]) => ({ client, testimonial }))
