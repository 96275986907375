import React from 'react'
import classnames from 'classnames'
import './Menu.scss'
import GetQuoteButton from '../../forms/GetQuoteButton'
import { isMobileView } from '../../helpers'

const Menu = ({ options, closeMenu, menu, quoteForm, fixed }) => (
  <>
    {isMobileView() && menu && !quoteForm && (
      <div
        style={{ zIndex: '1' }}
        className="formContainerScrim"
        onClick={closeMenu}
      />
    )}
    <div
      className={classnames(
        'menuContainer',
        !menu && 'menuContainerClosed',
        fixed && 'menuContainerFixed'
      )}
    >
      {options.map(({ label, href }) => (
        <a
          key={label}
          className="menuItem"
          {...{ href }}
          onClick={isMobileView() ? closeMenu : undefined}
        >
          {label}
        </a>
      ))}
      <GetQuoteButton color="Menu" />
    </div>
  </>
)

export default Menu
