import React from 'react'
import './RepertoirePage.scss'
import RepertoireItem from '../RepertoireItem/RepertoireItem'

const RepertoirePage = ({ item, onClick, swipeHandlers }) => (
  <div className="repertoirePage" {...{ onClick, ...swipeHandlers }}>
    {item ? (
      Array.from(item).map(song => (
        <RepertoireItem key={song.title} {...song} />
      ))
    ) : (
      <p className="repertoireLoading">Loading Repertoire...</p>
    )}
  </div>
)

export default RepertoirePage
