import React from 'react'
import './Header.scss'
import { SocialContainer, ImageContainer } from '../../containers'
import { isMobileView } from '../../helpers'

const Header = ({ menu, openMenu, closeMenu, closeForm, quoteForm }) => {
  const closeAll = () => {
    menu && closeMenu()
    quoteForm && closeForm()
  }

  return (
    <div className="header">
      <div className="headerAnchor" id="HOME" />
      <div
        className="headerWrapperTopline"
        onClick={isMobileView() ? closeAll : undefined}
      >
        <h1 className="headerMain">RHYTHM OF THE NIGHT</h1>
        <ImageContainer
          className="headerMenuButton"
          role="button"
          src="/imgs/navigation/menu.svg"
          alt="Tap to open menu"
          onClick={menu ? (quoteForm ? closeForm : closeAll) : openMenu}
        />
      </div>
      <div className="headerWrapper">
        <h3 className="headerSub">THE ULTIMATE PARTY BAND</h3>
        <SocialContainer
          iconClass="headerSocial"
          wrapperClass="headerSocialWrapper"
        />
      </div>
    </div>
  )
}

export default Header
