import React, { createContext, useState, useEffect, useRef } from 'react'
import './App.scss'

import {
  Header,
  YouTubePlayer,
  SoundcloudPlayer,
  InstagramProfile,
  Menu,
  Bio,
} from './components'

import {
  SectionContainer,
  RepertoireContainer,
  GalleryContainer,
  TestimonialsContainer,
  ClientsContainer,
  ContactContainer,
} from './containers'

import LazyFormContainer from './forms/LazyFormContainer'

import {
  baseSheetUrl,
  repertoireId,
  testimonialId,
  imageLinks,
  clientLinks,
  bioText,
  phone,
  email,
} from './constants'

import {
  get,
  repertoireConverter,
  testimonialConverter,
  mapMenuOptions,
  enableScroll,
  disableScroll,
} from './helpers'

import useToggle from './hooks/useToggle'

export let QuoteButtonContext

const App = () => {
  const appRef = useRef()
  const wrapperRef = useRef()

  const [fixed, setFixed] = useState(false)
  const [menu, toggleMenu] = useToggle(false)
  const [repertoire, setRepertoire] = useState([])
  const [testimonials, setTestimonials] = useState([])
  const [quoteForm, toggleQuoteForm] = useToggle(false)

  const openForm = () => {
    toggleQuoteForm()
    disableScroll()
  }

  const closeForm = () => {
    toggleQuoteForm()
    !menu && enableScroll()
  }

  const openMenu = () => {
    toggleMenu()
    disableScroll()
  }

  const closeMenu = () => {
    toggleMenu()
    enableScroll()
  }

  QuoteButtonContext = createContext(openForm)

  const getHeaderHeight = () =>
    appRef.current.getBoundingClientRect().height -
    wrapperRef.current.getBoundingClientRect().height

  const handleScroll = () => {
    if (!fixed && window.scrollY > getHeaderHeight()) {
      setFixed(true)
    } else if (fixed && window.scrollY < getHeaderHeight()) {
      setFixed(false)
    }
  }

  useEffect(() => {
    console.log(process.env)
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  })

  const fetchRepertoire = () =>
    get(baseSheetUrl(repertoireId))
      .then(data => {
        setRepertoire(repertoireConverter(data))
      })
      .catch(() => {
        setRepertoire([{ title: 'Error', artist: 'Cannot access repertiore' }])
      })

  const fetchTestimonials = () =>
    get(baseSheetUrl(testimonialId))
      .then(data => {
        setTestimonials(testimonialConverter(data))
      })
      .catch(() => {
        setTestimonials([
          { testimonial: 'Error - Cannot access testimonials', client: '' },
        ])
      })

  useEffect(() => {
    fetchRepertoire().then(fetchTestimonials)
  }, [])

  const sections = [
    {
      id: 'BIO',
      Component: Bio,
      props: { bioText },
    },
    {
      id: 'POSTS',
      Component: InstagramProfile,
    },
    {
      id: 'MUSIC',
      Component: SoundcloudPlayer,
    },
    {
      id: 'REPERTOIRE',
      Component: RepertoireContainer,
      props: { repertoire },
    },
    {
      id: 'GALLERY',
      Component: GalleryContainer,
      props: { imageLinks, disableScroll, enableScroll },
    },
    {
      id: 'TESTIMONIALS',
      Component: TestimonialsContainer,
      props: { testimonials },
    },
    {
      id: 'CLIENTS',
      Component: ClientsContainer,
      props: { clientLinks },
    },
    {
      id: 'CONTACT',
      Component: ContactContainer,
      props: { phone, email },
    },
  ]

  const options = mapMenuOptions(sections)

  return (
    <div className="App" id="#HOME" ref={appRef}>
      {quoteForm && <LazyFormContainer {...{ closeForm }} />}
      <Header {...{ menu, openMenu, closeMenu, closeForm, quoteForm }} />
      <YouTubePlayer />
      <div className="mainSiteWrapper" ref={wrapperRef}>
        <Menu {...{ options, closeMenu, menu, quoteForm, fixed }} />
        {sections.map(({ id, Component, props }) => (
          <SectionContainer id={id} key={id}>
            <Component {...props} />
          </SectionContainer>
        ))}
      </div>
    </div>
  )
}

export default App
