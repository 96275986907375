import React from 'react'
import './ContactContainer.scss'
import { SocialContainer } from '.'

const ContactContainer = ({ phone, email }) => (
  <div className="contactContainer">
    <p className="contactLinkWrapper">
      Contact Craig:{' '}
      <a className="contactLink" href={`tel:${phone.replace(/ /g, '')}`}>
        {phone}
      </a>
    </p>
    <p className="contactLinkWrapper">
      <a className="contactLink" href={`mailto:${email}`}>
        {email}
      </a>
    </p>
    <SocialContainer className="contactSocialContainer" />
  </div>
)
export default ContactContainer
