const headers = {
  "Content-Type": "application/x-www-form-urlencoded"
}

const encode = (data) => (
  Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
)

export const get = url => fetch(url).then(resp => resp.json())

export const post = (url, body) => (
  fetch(url, {
    method: "POST",
    headers,
    body: encode(body)
  })
)