import React from 'react'
import './SectionContainer.scss'

const SectionContainer = ({ id, children }) => (
  <div className="sectionContainer">
    <span className="sectionContainerAnchor" {...{ id }} />
    {children}
  </div>
)

export default SectionContainer
