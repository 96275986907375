import React, { Suspense, lazy } from 'react'
import "./SoundcloudPlayer.scss"

const SoundcloudFrame = lazy(() => import("./SoundcloudFrame"))

const fallback = <div className="soundcloudFrame">Fetching Tracks...</div>

const LazySoundcloudFrame = () => (
  <Suspense {...{fallback}}>
    <SoundcloudFrame />
  </Suspense>
)

export default LazySoundcloudFrame