import React, { lazy, Suspense } from 'react'
import "./FormContainer.scss"

const FormContainer = lazy(() => import("./FormContainer"))

const fallback = (
  <>
    <div className="formContainerScrim" />
    <div className="formContainer">
      Fetching Form...
    </div>
  </>
) 

const LazyFormContainer = ({ closeForm }) => (
  <Suspense {...{ fallback }}>
    <FormContainer {...{ closeForm }}/>
  </Suspense>
)

export default LazyFormContainer