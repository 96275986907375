import React, { useState, useEffect, createElement } from 'react'
import { useSwipeable } from 'react-swipeable'
import './CarouselContainer.scss'
import classnames from 'classnames'
import ImageContainer from '../containers/ImageContainer'
import navIcons from '../constants/navigationIcons'
import { isMobileView } from '../helpers'

const CarouselContainer = ({ items, component, interval = 10000 }) => {
  const [index, setIndex] = useState(0)

  const lastItem = index + 1 === items.length
  const increaseIndex = () => setIndex(lastItem ? 0 : index + 1)
  const decreaseIndex = () => setIndex(index ? index - 1 : items.length - 1)

  const [running, setRunning] = useState(true)
  const toggleCarousel = () => setRunning(!running)

  const swipeHandlers = useSwipeable({
    onSwipedLeft: running && decreaseIndex,
    onSwipedRight: running && increaseIndex,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    delta: isMobileView() ? 50 : 100,
  })

  const { leftArrow, rightArrow, play, pause } = navIcons

  const componentProps = {
    onClick: toggleCarousel,
    item: items[index],
    swipeHandlers,
  }

  useEffect(() => {
    const carouselTimer = running && setTimeout(increaseIndex, interval)
    return () => clearTimeout(carouselTimer)
  }, [running, index, lastItem, interval])

  return (
    <div className="carouselContainer">
      {createElement(component, componentProps)}
      <div className="carouselButtonContainer">
        <ImageContainer
          className={classnames(
            'carouselButton',
            !running && 'carouselButtonDisabled'
          )}
          role="button"
          onClick={running ? decreaseIndex : undefined}
          src={leftArrow}
        />
        <ImageContainer
          className="carouselButton"
          role="button"
          onClick={toggleCarousel}
          src={running ? pause : play}
        />
        <ImageContainer
          className={classnames(
            'carouselButton',
            !running && 'carouselButtonDisabled'
          )}
          role="button"
          onClick={running ? increaseIndex : undefined}
          src={rightArrow}
        />
      </div>
      <span className="carouselCount">
        {index + 1} of {items.length}
      </span>
    </div>
  )
}

export default CarouselContainer
