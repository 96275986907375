import React from 'react'
import './TestimonialsContainer.scss'
import { CarouselContainer } from '.'
import { Testimonial } from '../components'
import GetQuoteButton from '../forms/GetQuoteButton'

const TestimonialsContainer = ({ testimonials }) => (
  <div className="testimonialsContainer">
    <h3 className="testimonialsContainerHeading">TESTIMONIALS</h3>
    <CarouselContainer items={testimonials} component={Testimonial} />
    <GetQuoteButton color="Red" />
  </div>
)

export default TestimonialsContainer
