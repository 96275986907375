import React, { useState, useRef } from 'react'
import { useSwipeable } from 'react-swipeable'
import './GalleryContainer.scss'
import classnames from 'classnames'
import ImageContainer from './ImageContainer'
import LazyImageContainer from './LazyImageContainer'
import navIcons from '../constants/navigationIcons'
import { isMobileView } from '../helpers'

const GalleryContainer = ({ imageLinks, disableScroll, enableScroll }) => {
  const wrapperRef = useRef()
  const buttonWrapperRef = useRef()

  const [index, setIndex] = useState(undefined)

  const setSolo = i => {
    setIndex(i)
    disableScroll()
  }

  const clearSolo = () => {
    setIndex(undefined)
    enableScroll()
  }

  const increaseSolo = () => {
    setIndex(index === imageLinks.length - 1 ? 0 : index + 1)
  }

  const decreaseSolo = () => {
    setIndex(index ? index - 1 : imageLinks.length - 1)
  }

  const handleWrapperClick = ({ target }, { current }) => {
    target === current && clearSolo()
  }

  const swipeHandlers = useSwipeable({
    onSwipedLeft: decreaseSolo,
    onSwipedRight: increaseSolo,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    delta: isMobileView() ? 50 : 100,
  })

  const { leftArrowRed, rightArrowRed, back } = navIcons

  return (
    <div className="galleryWrapper">
      <h3 className="galleryHeader">GALLERY</h3>
      <div className="galleryContainer">
        {index !== undefined && (
          <div
            className="galleryImageSoloWrapper"
            ref={wrapperRef}
            onClick={e => handleWrapperClick(e, wrapperRef)}
          >
            <ImageContainer
              src={imageLinks[index]}
              className={classnames(
                'galleryImageSolo',
                index < 2 && 'galleryImageSoloTall'
              )}
              {...{ swipeHandlers }}
            />
            <div
              className="galleryImageSoloButtonWrapper"
              ref={buttonWrapperRef}
              onClick={e => handleWrapperClick(e, buttonWrapperRef)}
            >
              <ImageContainer
                className="galleryImageSoloButton"
                role="button"
                onClick={decreaseSolo}
                src={leftArrowRed}
              />
              <ImageContainer
                className="galleryImageSoloButton"
                role="button"
                onClick={clearSolo}
                src={back}
              />
              <ImageContainer
                className="galleryImageSoloButton"
                role="button"
                onClick={increaseSolo}
                src={rightArrowRed}
              />
            </div>
          </div>
        )}
        {imageLinks.map((src, i) => (
          <LazyImageContainer
            key={i}
            role="button"
            onClick={() => setSolo(i)}
            className="galleryImage"
            {...{ src }}
          />
        ))}
      </div>
    </div>
  )
}

export default GalleryContainer
