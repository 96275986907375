import React from 'react'
import classnames from 'classnames'
import './InstagramProfile.scss'
import '../../containers/SocialContainer.scss'
import ImageContainer from '../../containers/ImageContainer'
import { socialSites } from '../../constants'

const InstagramProfile = () => {
  const instagram = socialSites.find(({ icon }) => icon === 'instagram')
  const { icon, href } = instagram
  return (
    <div className="instagramProfile">
      <div className="instagramProfileHeaderWrapper">
        <h3 className="instagramProfileHeader">POSTS</h3>
        <a
          href={href}
          target="blank"
          className={classnames('socialLink', 'instagramProfileIcon')}
        >
          <ImageContainer
            className="socialIcon"
            alt={icon}
            src={`/imgs/social_logos/${icon}.svg`}
            width="3vw"
          />
        </a>
      </div>
      <script src="https://cdn.lightwidget.com/widgets/lightwidget.js"></script>
      <iframe
        title="ROTN-instagram"
        src="https://cdn.lightwidget.com/widgets/470f8c731c1a565d8544ac09163712ec.html"
        scrolling="no"
        allowtransparency="true"
        className={classnames('lightwidget-widget', 'instagramProfileFeed')}
      ></iframe>
    </div>
  )
}

export default InstagramProfile
