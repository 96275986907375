import React from 'react'
import './Bio.scss'
import LazyImageContainer from '../../containers/LazyImageContainer'
import GetQuoteButton from '../../forms/GetQuoteButton'
import { imageLinks } from '../../constants/imageLinks'

const Bio = ({ bioText }) => {
  const [first, second, third] = bioText
  return (
    <div className="bioContent">
      <h3 className="bioHeader">BIO</h3>
      <div className="bioContentHigher">
        <p className="bioText">{first}</p>
        <LazyImageContainer
          className="bioImage"
          src={imageLinks[0]}
          alt="Rhythm Of The Night"
        />
      </div>
      <p className="bioText">{second}</p>
      <div className="bioContentLower">
        <p className="bioText">{third}</p>
        <LazyImageContainer
          className="bioImageLower"
          src={imageLinks[3]}
          alt="Rhythm Of The Night"
        />
      </div>
      <GetQuoteButton color="White" />
    </div>
  )
}

export default Bio
