import React, { Suspense, lazy } from 'react'
import "./ImageContainer.scss"

const ImageContainer = lazy(() => import("./ImageContainer"))

const LazyImageContainer = ({ className, src, alt, onClick }) => {
  const fallback = imageClass => (
    <div className="imageContainer">
      <div className={imageClass}>
        Fetching Image...
      </div>
    </div>
  )

  return (
    <Suspense fallback={fallback(className)} >
      <ImageContainer {...{ className, src, alt, onClick }}/>
    </Suspense>
  )
}

export default LazyImageContainer