import { useState } from "react"

const useToggle = (state=false) => {
   const [boolean, setBoolean] = useState(!!state)

   const toggleBoolean = () => setBoolean(!boolean)

   return [boolean, toggleBoolean]
}

export default useToggle