import React from 'react' 
import './RepertoireItem.scss'

const RepertoireItem = ({ artist, title }) => (
  <p className='repertoireItem'>
    <span className="repertoireItemTitle">{title}</span>
    <span className="repertoireItemArtist">{artist}</span>
  </p>
)

export default RepertoireItem