import React from 'react'
import './ImageContainer.scss'

const ImageContainer = ({
  className,
  src,
  alt,
  onClick,
  swipeHandlers,
  ...rest
}) => (
  <div className="imageContainer">
    <img {...{ className, src, alt, onClick, ...swipeHandlers, ...rest }} />
  </div>
)

export default ImageContainer
