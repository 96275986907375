import React from 'react'
import './YoutubePlayer.scss'

const YouTubePlayer = () => (
  <div className="youtubeWrapper">
    <iframe 
      className='youtubeWrapperFrame'
      src="//www.youtube.com/embed/e0s8C2DgjsU?rel=0&autoplay=1&mute=1"
      frameBorder="0" 
      allowFullScreen
      title="Rhythm Of The Night - The Ultimate Party Band"
    />
  </div>
)

export default YouTubePlayer