import React from 'react'
import './Testimonial.scss'

const Testimonial = ({ onClick, item, swipeHandlers }) => (
  <div className="testimonialCardWrapper">
    <div className='testimonialCard' {...{ onClick, ...swipeHandlers }}>
      <p className="testimonialCardTestimonial">
        {item ? item.testimonial : "Loading Testimonials..."}
      </p>
    </div>
    <span className="testimonialCardClient">
      {item && `- ${item.client}`}
    </span>
  </div>
)

export default Testimonial