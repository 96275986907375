import React, { useContext } from 'react'
import { QuoteButtonContext } from "../App"
import classnames from "classnames"
import "./QuoteButton.scss"

const GetQuoteButton = ({ color }) => {
  const onClick = useContext(QuoteButtonContext)
  const colorClass = `quoteButton${color}`
  return (
    <button
      className={classnames('quoteButton', colorClass)}
      {...{ onClick }}
    >
      GET A QUOTE
    </button>
  )
}

export default GetQuoteButton