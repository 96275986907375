const images = [
  '1-bio-profile',
  '6-alt-bio-profile',
  '2-dark-profile',
  '3-dark-wide-profile',
  '4-distant-profile',
  '5-smart-closeup'
]

const clients = [
  'f1',
  'timeout',
  'emirates',
  'bfi',
  'ritz',
  'ikea',
  '4seasons',
  'dorchester',
  'nandos',
  'volvo-ocean-race',
  'blue-marlin-ibiza',
  'oberoi-hotels',
  'pwc',
  'centerparcs',
  'army',
  'royal-hospital-school'
]

export const imageLinks = images.map(file => `/imgs/${file}-low.jpg`)

export const clientLinks = clients.map(file => `/imgs/clients/${file}.jpg`)