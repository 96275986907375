import React from 'react'
import "./SoundcloudPlayer.scss"
import LazySoundcloudFrame from "./LazySoundcloudFrame"

const SoundcloudPlayer = () => (
  <div className="soundcloudPlayer">
    <h3 className="soundcloudPlayerHeader">MUSIC</h3>
    <LazySoundcloudFrame />
  </div>
)

export default SoundcloudPlayer