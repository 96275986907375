import React from 'react'
import LazyImageContainer from "./LazyImageContainer"
import './ClientsContainer.scss'

const ClientsContainer = ({ clientLinks }) => (
  <div className="clientsContainerWrapper">
    <h3 className="clientsContainerHeading">CLIENTS</h3>
    <div className="clientsContainer">
      {clientLinks.map((src, i) => <LazyImageContainer key={i} {...{ src }} className="clientsContainerImage"/>)}
    </div>
  </div>
)

export default ClientsContainer